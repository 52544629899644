import React from 'react';
import { map, get } from 'lodash';
import { AdInfoItem } from './adInfoItem';

export const AdInfo = ({ data }: any) => {
  const views = get(data, 'views', 0);
  const proposals = get(data, 'proposals', 0);
  const wppViews = get(data, 'wppViews', 0);
  const infoItems = [
    {
      color: 'vibrant-green',
      backgroundColor: '#CAF7C8',
      icon: 'SvgIconShowMe',
      title: `${views}`,
      subtitle: 'Visualizações',
    },
    {
      color: 'purpley-pink',
      backgroundColor: '#F1D3EB',
      icon: 'SvgIconMail',
      title: `${proposals}`,
      subtitle: 'Propostas',
    },
    {
      color: 'purpley-pink',
      backgroundColor: '#F1D3EB',
      icon: 'SvgIconMail',
      title: `${wppViews}`,
      subtitle: 'Propostas wpp',
    },
  ];

  const getItems = () => {
    return map(infoItems, (item, index) => <AdInfoItem info={item} key={index} />);
  };

  return <div className="flex flex-nowrap overflow-auto flex-wrap-ns mw-100">{getItems()}</div>;
};
